
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import PageTitle from '@/components/common/PageTitle.vue';
import SectionTitle from '@/components/common/SectionTitle.vue';
import TimeToEndOfStage from '@/components/assessmentChecklist/TimeToEndOfStage.vue';
import NoDataStub from '@/components/common/NoDataStub.vue';
import ReviewExpertGroupList from '@/components/assessmentChecklist/ReviewExperts/ReviewExpertGroupList.vue';
import { V1EntitiesReviewsIndexReview } from '@/services/api/tsxass';

export default Vue.extend({
  name: 'AssessmentReviewExperts',

  components: {
    PageTitle,
    SectionTitle,
    TimeToEndOfStage,
    NoDataStub,
    ReviewExpertGroupList,
  },

  data() {
    return {
      loading: false,
      reviews: null as V1EntitiesReviewsIndexReview[] | null,
    };
  },

  computed: {
    ...mapState('survey', {
      surveyMeta: 'meta',
    }),
    ...mapGetters('survey', {
      surveyStageReviewExperts: 'surveyStageReviewExperts',
      currentDisplayedSurveyStage: 'currentDisplayedSurveyStage',
    }),

    isBigScreen(): boolean {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    surveyId(): number {
      return Number(this.$route.params.surveyId);
    },
    stageDateEnd(): string | null {
      return this.surveyStageReviewExperts?.dateEnd;
    },
    isNotCurrentStage(): boolean {
      if (!this.currentDisplayedSurveyStage) {
        return false;
      }

      const todayIsBetweenDates = this.$dayjs().isBetween(
        this.$dayjs(this.currentDisplayedSurveyStage.dateStart),
        this.$dayjs(this.currentDisplayedSurveyStage.dateEnd),
        // @ts-ignore
        '[]',
      );
      return this.surveyMeta.status !== 'finished' && !todayIsBetweenDates;
    },
    isShowSubtitle(): boolean {
      return !this.isNotCurrentStage && (Array.isArray(this.reviews) && this.reviews.length > 0);
    },
  },
});
