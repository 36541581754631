
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ReviewExpertsBanner',

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isShowBanner: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit('update:show', value);
      },
    },
  },
});
