
import Vue, { PropType } from 'vue';
import { UserCardColumnNames } from '@/constants/userForRateColumns';
import errorHandler from '@/helpers/errorHandler';
import { tsxassApi } from '@/services/api';
import {
  V1EntitiesReviewsAudience,
  V1EntitiesReviewsIndexReview,
} from '@/services/api/tsxass';
import TTLoader from '@/components/ui/TTLoader.vue';
import ReviewExpertsBanner from '@/components/assessmentChecklist/ReviewExperts/ReviewExpertsBanner.vue';
import { UserCardColumn } from '@/components/common/UserGroupTable/types';
import UserExpertTable from '@/components/assessmentChecklist/ReviewExperts/UserExpertTable.vue';
import UserCard from '@/components/common/UserGroupTable/UserCard.vue';

enum ReviewStatus {
  REVIEW = 'review',
  APPROVED = 'approved',
}

export default Vue.extend({
  name: 'ReviewExpertGroupList',

  components: {
    UserCard,
    UserExpertTable,
    ReviewExpertsBanner,
    TTLoader,
  },

  inject: ['RouteNames'],

  props: {
    columns: {
      type: Array as PropType<UserCardColumn[]>,
      default(): UserCardColumn[] {
        return [
          {
            name: UserCardColumnNames.EMPLOYEE,
            title: this.$t('userForRate.employee') as string,
            width: '6fr',
          },
          {
            name: UserCardColumnNames.TEAM,
            title: this.$t('userForRate.team') as string,
            width: '5fr',
          },
          {
            name: UserCardColumnNames.ACTION,
            title: '',
            width: '5fr',
          },
        ];
      },
    },
    surveyId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      reviews: [] as V1EntitiesReviewsIndexReview[],
      isShowBanner: false,
      UserCardColumnNames,
    };
  },

  computed: {
    reviewItems() {
      return this.reviews.filter((review) => review.status === ReviewStatus.REVIEW).map((item) => item.surveyee);
    },
    approvedItems() {
      return this.reviews.filter((review) => review.status === ReviewStatus.APPROVED).map((item) => item.surveyee);
    },
    isAllApproved() {
      return this.reviewItems.length === 0 && this.approvedItems.length > 0;
    },
  },

  async mounted() {
    await this.loadReviews();
    await this.setBannerVisibility();
  },

  methods: {
    async loadReviews() {
      try {
        this.loading = true;
        const { data: { reviews } } = await tsxassApi.getV1ReviewsSurveyId(this.surveyId);
        this.reviews = reviews;
        this.$emit('load-reviews', reviews);
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async approveAllExperts() {
      try {
        this.loading = true;
        await tsxassApi.postV1ReviewsSurveyIdAll(this.surveyId);
        await this.loadReviews();
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    viewExpertsList(item: V1EntitiesReviewsAudience) {
      this.$router.push({
        name: this.RouteNames.R_APP_ASSESSMENT_REVIEW_EXPERT,
        params: { surveyeeId: String(item.userId) },
      });
    },
    setBannerVisibility() {
      this.isShowBanner = this.reviewItems.length === 0 && this.approvedItems.length > 0;
    },
  },
});
